<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedPointId"
                    :items="availablePoints"
                    item-text="name"
                    item-value="id"
                    :label="$t(`common['Select Point Setting']`)"
                    :disable="!isPeriodsLoaded"
                    clearable
                  >
                    <!-- <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon>
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template> -->
                    <!-- <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedStores.length - 1 }} others)
                  </span>
                </template> -->
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getPointPeriods();
                      "
                      :disable="!isPeriodsLoaded"
                      :loading="!isPeriodsLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              期別列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchPeriod"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tablePeriodsHeaders"
                :items="periods"
                :loading="!isPeriodsLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="searchPeriod"
                :sort-by="['closeStatus', 'starTs', 'endTs']"
                :sort-desc="[false, true, true]"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <!-- <template v-slot:no-data> NO DATA HERE! </template> -->
                <template v-slot:item.closeStatus="{ item }">
                  <span>{{ item.closeStatus ? "已結算" : "未結算" }}</span>
                </template>

                <template v-slot:item.startTs="{ item }">
                  <span>{{
                    item.startTs
                      ? moment.unix(item.startTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.endTs="{ item }">
                  <span>{{
                    item.endTs
                      ? moment.unix(item.endTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="gotoBudgetsPage(props.item.id)"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看期別預算</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="cyan"
                        v-on="on"
                        @click="onEditItem(props.item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="canClose">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.closeStatus === 0
                            ? 'pink darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.closeStatus === 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.closeStatus === 0
                            ? onSettlementItemClick(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-wallet</v-icon>
                      </v-btn>
                    </template>
                    <span>結算</span>
                  </v-tooltip>
                  <!-- <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    @click="onEditItem(props.item)"
                  >
                    <v-icon dark>fa-edit</v-icon>
                  </v-btn> -->
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="periods.length"
                :loading="!isPeriodsLoaded"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="openEditDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`periods["Settings"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="editItem.pointId"
                      :label="$t(`points['Name']`)"
                      :items="availablePoints"
                      item-text="name"
                      item-value="id"
                      :rules="rules.required"
                      required
                      :no-data-text="$t(`common['No Data']`)"
                      :disabled="!isNew"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.name"
                      :label="$t(`periods['Name']`)"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-row v-if="editItem.start">
                      <v-col cols="5">
                        <label class="text-sm text-body">開始日期</label>
                        <v-select
                          :items="months"
                          v-model="editItem.start.month"
                          label="Month"
                          item-text="name"
                          item-value="value"
                          color="#e91e63"
                          class="font-size-input input-style"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="4" cols="3">
                        <v-select
                          :items="days"
                          v-model="editItem.start.day"
                          label="Day"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="3" cols="4">
                        <v-select
                          :items="years"
                          v-model="editItem.start.year"
                          label="Year"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row v-if="editItem.end">
                      <v-col cols="5">
                        <label class="text-sm text-body">結束日期</label>
                        <v-select
                          :items="months"
                          v-model="editItem.end.month"
                          label="Month"
                          item-text="name"
                          item-value="value"
                          color="#e91e63"
                          class="font-size-input input-style"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="4" cols="3">
                        <v-select
                          :items="days"
                          v-model="editItem.end.day"
                          label="Day"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                      <v-col sm="3" cols="4">
                        <v-select
                          :items="years"
                          v-model="editItem.end.year"
                          label="Year"
                          color="#e91e63"
                          class="font-size-input input-style mt-7"
                          single-line
                          height="36"
                          :rules="rules.required"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="showStartPicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      name="startDate"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editItem.startDate"
                          label="開始日期"
                          readonly
                          :rules="rules.required"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editItem.startDate"
                        color="blue-grey lighten-1"
                        @input="showStartPicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="showEndPicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      name="endDate"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editItem.endDate"
                          label="結束日期"
                          readonly
                          :rules="rules.required"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editItem.endDate"
                        color="blue-grey lighten-1"
                        @input="showEndPicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditItem">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openSettlementDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">
              期別結算：{{ settlementPeriod && settlementPeriod.name }}
            </span>
          </v-card-title>
          <v-card-subtitle class="pt-2">
            <span class="subtitle font-weight-light">
              結算前，請確認未分配之期別預算與未使用的單位預算均已正確轉讓至下期預算；
              <br />
              結算後，過期點數將保留至指定的單位預算，並將期別設置為已結算。
            </span>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-form ref="transferForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="settlementDepartment.departmentName"
                      label="結轉單位"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="transferredBudgetId"
                      :items="availableTransferBudgets"
                      item-text="budgetName"
                      item-value="id"
                      :label="$t(`budgets['Name']`)"
                      :no-data-text="$t(`common['No Data']`)"
                      :rules="rules.required"
                    >
                      <!-- <template slot="selection" slot-scope="data">
                        {{ data.item.budgetName }} （{{
                          data.item.departmentName
                        }}）
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.budgetName }} （{{
                          data.item.departmentName
                        }}）
                      </template> -->
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCloseSettlementDialog">
              {{ $t(`common['Cancel']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSettlementClick">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchPointSettings,
  fetchPointPeriods,
  fetchDepartmentBudgets,
  fetchSettlementDepartment,
} from "src/apis/fetchData";
import { updatePointPeriod } from "src/apis/updateData";
import { closePointPeriod } from "src/apis/pointsCore";

// import {
//   generateArrayOfYears,
//   generateArrayOfDays,
//   generateArrayOfMonths,
// } from "src/util/utils";
import { checkPermission } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  components: { Pagination },
  data() {
    return {
      moment: moment,
      showStartPicker: false,
      showEndPicker: false,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      isPeriodsLoaded: true,
      isNew: false,
      openEditDialog: false,
      availablePoints: [],
      selectedPointId: null,
      currentPointId: null,
      periods: [],
      tablePeriodsHeaders: [
        {
          text: this.$t(`periods["Name"]`),
          sortable: true,
          value: "name",
        },
        {
          text: this.$t(`common["Status"]`),
          sortable: true,
          filterable: false,
          value: "closeStatus",
        },
        {
          text: this.$t(`common["Start Time"]`),
          sortable: true,
          filterable: false,
          value: "startTs",
        },
        {
          text: this.$t(`common["End Time"]`),
          sortable: true,
          filterable: false,
          value: "endTs",
        },
        { text: "", value: "actions", sortable: false },
      ],
      searchPeriod: null,
      defaultEditItem: {
        pointId: null,
        name: null,
        startDate: null,
        endDate: null,
      },
      editItem: {
        pointId: null,
        name: null,
        startDate: null,
        endDate: null,
      },
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        name: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      // months: [],
      // days: [],
      // years: [],
      // start: {},
      // end: {},
      settlementPeriod: null,
      transferredBudgetId: null,
      openSettlementDialog: false,
      availableTransferBudgets: [],
      settlementDepartment: {
        departmentId: null,
        departmentName: null,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "EditPointPeriods",
          true
        );
      }
      return false;
    },
    canClose() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "ClosePointPeriods",
          true
        );
      }
      return false;
    },
  },
  watch: {
    // startTsmenu(val) {
    //   val && setTimeout(() => (this.startTsActivePicker = "YEAR"));
    // },
    // endTsmenu(val) {
    //   val && setTimeout(() => (this.endTsActivePicker = "YEAR"));
    // },
    // settlementDepartment: {
    //   handler(val) {
    //     if (val && val.departmentId) {
    //       this.getDepartmentBudgets();
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getPointSettings();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getPointSettings();
    }
    // this.years = generateArrayOfYears();
    // this.days = generateArrayOfDays();
    // this.months = generateArrayOfMonths();
  },
  methods: {
    gotoBudgetsPage(id) {
      this.$router.push({
        name: "Annual Budget",
        query: {
          pointId: this.currentPointId,
          periodId: id,
        },
      });
    },
    getPointSettings() {
      const merchantId = this.ssoUser.user._json.groups.merchant;
      let matchRouteQuery = false;

      // console.log("before ", this.pointSettings);
      if (this.pointSettings && this.pointSettings.length) {
        this.availablePoints = [...this.pointSettings];
        if (
          this.availablePoints &&
          this.availablePoints.length &&
          this.$route.query.pointId
        ) {
          const result = this.availablePoints.filter(
            (point) => point.id === parseInt(this.$route.query.pointId)
          );
          matchRouteQuery = result.length ? true : false;
        }
        this.selectedPointId = this.availablePoints.length
          ? matchRouteQuery
            ? this.$route.query.pointId
            : this.availablePoints[0].id
          : null;
        this.$nextTick(this.getPointPeriods());
        return;
      }
      fetchPointSettings(merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          console.log(
            "this.availablePoints:" + JSON.stringify(this.availablePoints)
          );
          if (
            this.availablePoints &&
            this.availablePoints.length &&
            this.$route.query.pointId
          ) {
            const result = this.availablePoints.filter((point) => {
              return point.id === parseInt(this.$route.query.pointId);
            });
            matchRouteQuery = result.length ? true : false;
          }

          this.selectedPointId = this.availablePoints.length
            ? matchRouteQuery
              ? parseInt(this.$route.query.pointId)
              : this.availablePoints[0].id
            : null;
          this.$store.commit("setPointSettings", res.data);
          this.$nextTick(this.getPointPeriods());
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        });
    },
    getPointPeriods(pid) {
      const merchantId = this.ssoUser.user._json.groups.merchant;
      console.log("getPointPeriods:" + this.selectedPointId);
      this.isPeriodsLoaded = false;
      const pointId = pid ? pid : this.selectedPointId;
      fetchPointPeriods(merchantId, pointId)
        .then((res) => {
          console.log("fetchPointPeriods done", res);
          this.periods = res.data;
        })
        .catch((e) => {
          console.log("fetchPointPeriods failed", e);
          this.$swal({
            text: "無法取得點數期別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isPeriodsLoaded = true;
          this.currentPointId = this.selectedPointId;
        });
    },
    getSettlementDeparmtnet() {
      fetchSettlementDepartment(this.merchantId)
        .then((res) => {
          console.log("fetchSettlementDepartment done", res);
          this.settlementDepartment = res.data;
          this.$nextTick(this.getDepartmentBudgets);
        })
        .catch((e) => {
          console.log("fetchPointPeriods failed", e);
          let errmsg = "無法取得結算預設單位資料";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.$swal({
            html: errmsg,
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.onCloseSettlementDialog();
        })
        .finally(() => {
          this.isPeriodsLoaded = true;
          this.currentPointId = this.selectedPointId;
        });
    },
    getDepartmentBudgets() {
      // const merchantId = 1;
      let query = {
        // periodId: this.settlementPeriod.id,
        departmentId: this.settlementDepartment.departmentId,
        approved: 1,
      };
      console.log("getDepartmentBudgets:" + JSON.stringify(query));

      fetchDepartmentBudgets(
        this.merchantId,
        this.settlementPeriod.pointId,
        query
      )
        .then((res) => {
          console.log("getDepartmentBudgets done", res);
          this.availableTransferBudgets = res.data.filter(
            (i) =>
              i.periodId !== this.settlementPeriod.id && i.closeStatus === 0
          );
        })
        .catch((e) => {
          console.log("getDepartmentBudgets failed", e);
          this.$swal({
            text: "無法取得單位預算資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.openEditDialog = false;
    },
    onEditItem(item) {
      if (item) {
        this.editItem = Object.assign({}, item);
        this.editItem.startDate = moment
          .unix(item.startTs)
          .format("YYYY-MM-DD");
        this.editItem.endDate = moment.unix(item.endTs).format("YYYY-MM-DD");
        // const start = moment.unix(item.startTs);
        // const end = moment.unix(item.endTs);
        // this.editItem.start = {
        //   year: start.format("YYYY"),
        //   month: start.format("MM"),
        //   day: start.format("DD"),
        // };
        // this.editItem.end = {
        //   year: end.format("YYYY"),
        //   month: end.format("MM"),
        //   day: end.format("DD"),
        // };
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.editItem.startDate = moment().format("YYYY-MM-DD");
        this.editItem.endDate = moment().add(1, "years").format("YYYY-MM-DD");
        this.editItem.pointId = this.currentPointId;
        // const start = moment();
        // const end = moment().add(1, "years");
        // this.editItem.start = {
        //   year: start.format("YYYY"),
        //   month: start.format("MM"),
        //   day: start.format("DD"),
        // };
        // this.editItem.end = {
        //   year: end.format("YYYY"),
        //   month: end.format("MM"),
        //   day: end.format("DD"),
        // };
        this.isNew = true;
      }
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();

      // const merchantId = this.ssoUser.user._json.groups.merchant;
      if (valid) {
        const start = this.editItem.startDate + " 00:00:00";
        const end = this.editItem.endDate + " 23:59:59";
        const startTs = moment(start);
        const endTs = moment(end);
        if (endTs.diff(startTs, "days") < 0) {
          this.$swal({
            text: "開始時間須早於結束時間",
            type: "error",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          return;
        }
        // const start =
        //   this.editItem.start.year.toString() +
        //   "-" +
        //   this.editItem.start.month.toString() +
        //   "-" +
        //   this.editItem.start.day.toString() +
        //   " 00:00:00";

        // const end =
        //   this.editItem.end.year.toString() +
        //   "-" +
        //   this.editItem.end.month.toString() +
        //   "-" +
        //   this.editItem.end.day.toString() +
        //   " 23:59:59";
        this.editItem.startTs = moment(start).unix();
        this.editItem.endTs = moment(end).unix();
        updatePointPeriod(this.merchantId, this.editItem.pointId, this.editItem)
          .then((res) => {
            console.log("udpatePointPeriod done", res);
            this.selectedPointId = this.editItem.pointId;
            this.getPointPeriods(this.editItem.pointId);
            this.openEditDialog = false;
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
          })
          .catch((e) => {
            console.log("udpatePointPeriod failed", e);
            let errmsg = this.isNew ? "新增期別設定失敗" : "更新期別設定失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onCloseSettlementDialog() {
      console.log("onCloseSettlementDialog");
      this.transferredBudgetId = null;
      this.settlementPeriod = null;
      this.$refs.transferForm.resetValidation();
      this.$refs.transferForm.reset();
      this.openSettlementDialog = false;
    },
    onSettlementItemClick(item) {
      this.settlementPeriod = Object.assign({}, item);
      this.openSettlementDialog = true;
      // 取得預設結算單位
      this.getSettlementDeparmtnet();

      // 選擇結算後點數轉讓對象
    },
    onSettlementClick() {
      const valid = this.$refs.transferForm.validate();
      if (valid) {
        const data = {
          receiverEntityType: "DepartmentBudget",
          receiverEntityId: this.transferredBudgetId.toString(),
          // points: this.transferFrom.transferPoints,
          note: "期別結算",
        };
        console.log("data:", data);
        closePointPeriod(this.merchantId, this.settlementPeriod.id, data)
          .then((res) => {
            console.log("closePointPeriod done", res);
            this.onCloseSettlementDialog();
            this.getPointPeriods(this.currentPointId);
          })
          .catch((e) => {
            console.log("closePointPeriod failed", e);
            let errmsg = `${this.settlementPeriod.name}結算失敗`;
            if (
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message
            ) {
              errmsg += "<br/>" + e.response.data.error.message;
            }
            this.showErrorAlert(errmsg, "結算失敗");
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        //this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`), "");
      }
    },
  },
};
</script>
