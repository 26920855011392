// import axios from "axios";

const version = "v1";
const basePath = "/core/points/";
export function fetchStoreOrders(merchantId, storeId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores/${storeId}/orders`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchStoreOrderStats(merchantId, storeId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores/${storeId}/order-stats`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

// 預算轉讓
export function pointAssignment(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/assignment`,
    data: data,
  };

  return window.qcsso.request(config);
}

// 期別結算
export function closePointPeriod(merchantId, periodId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/periods/${periodId}/close`,
    data: data,
  };

  return window.qcsso.request(config);
}

// 提領申請
export function updateWithdraw(merchantId, storeId, withdrawId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores/${storeId}/withdraw`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  if (withdrawId) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores/${storeId}/withdraw/${withdrawId}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
  }
  return window.qcsso.request(config);
}

// 預算回收
export function pointTransfer(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/transfer`,
    data: data,
  };

  return window.qcsso.request(config);
}
